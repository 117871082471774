import React, { FC } from 'react'
import { withPrefix } from "gatsby"

type Props = {
  title: string
}

export const HtmlHead: FC<Props> = ({ title }) => {
  return (
    <>
      <title>{`DKTech | ${title}`}</title>
      <script async src={withPrefix('menu.js')} />
    </>
  )
}